import request from '@/utils/request'

export function ComputedValues(data) {
  return request({
    url: '/api/app/reports/computed-values',
    method: 'get',
    params: data
  })
}

export function Create(data) {
  return request({
    url: '/api/app/reports',
    method: 'post',
    data
  })
}

export function Update(data) {
  return request({
    url: '/api/app/reports',
    method: 'put',
    data
  })
}

export function GetList(data) {
  return request({
    url: '/api/app/reports',
    method: 'get',
    params: data
  })
}

export function Detail(id) {
  return request({
    url: '/api/app/reports/' + id,
    method: 'get'
  })
}

export function Delete(id) {
  return request({
    url: '/api/app/reports/' + id,
    method: 'delete'
  })
}

// 每日统计数据
export function GetDailyReports(data) {
  return request({
    url: '/api/app/daily-records',
    method: 'get',
    params: data
  })
}

// 用户基础数据
export function GetUserReports(data) {
  return request({
    url: '/api/app/user-records',
    method: 'get',
    params: data
  })
}

// 订单基础数据
export function GetOrderReports(data) {
  return request({
    url: '/api/app/order-records',
    method: 'get',
    params: data
  })
}

// 车辆统计数据
export function GetVehicleDailyReports(data) {
  return request({
    url: '/api/app/vehicle-daily-record',
    method: 'get',
    params: data
  })
}

// 车辆数据
export function GetVehicleReports(data) {
  return request({
    url: '/api/app/vehicle-records',
    method: 'get',
    params: data
  })
}

// 钱包数据
export function GetWalletReports(data) {
  return request({
    url: '/api/app/wallet-records',
    method: 'get',
    params: data
  })
}

// 获取最后上传时间
export function GetLastImportTime(data) {
  return request({
    url: '/api/app/base-data/last-import-time',
    method: 'get',
    params: data
  })
}

export function GetImportTypes(data) {
  return request({
    url: '/api/app/base-data/get-import-types',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: 'post',
    data
  })
}

export function UploadImportData(data, area) {
  return request({
    url: '/api/app/base-data/import?area=' + area,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: 'post',
    data
  })
}

// 车辆数据
export function DeleteImportDate(data) {
  return request({
    url: '/api/app/base-data',
    method: 'delete',
    params: data
  })
}

// 数据统计
export function statistics(data) {
  return request({
    url: '/api/app/analysis/statistic',
    method: 'post',
    data
  })
}

// 订单数据查询
export function GetOrderData(data) {
  return request({
    url: '/api/app/order',
    method: 'get',
    params: data
  })
}

// 订单数据统计
export function GetOrderstatistics(data) {
  return request({
    url: '/api/app/order/statistics',
    method: 'get',
    params: data
  })
}

// 订单数据定位查询，用于车辆热力图
export function GetOrderLocation(data) {
  return request({
    url: '/api/app/order/locations',
    method: 'get',
    params: data
  })
}

export function GetOrderLocationOnly(data) {
  return request({
    url: '/api/app/order/locations-only',
    method: 'get',
    params: data
  })
}

export function SetOrderHandle(data) {
  return request({
    url: '/api/app/order/handle',
    method: 'post',
    data
  })
}

export function GetOrderVehicleStatistics(data) {
  return request({
    url: '/api/app/order/vehicle-statistics',
    method: 'get',
    params: data
  })
}

// 几乎是车辆实时数据，用于实时车辆热力图
export function GetDeviceScreen(data) {
  return request({
    url: '/api/app/device-screen',
    method: 'get',
    params: data
  })
}

export function UpdateDeviceScreen(data) {
  return request({
    url: '/api/app/device-screen/' + data.id,
    method: 'put'
  })
}

// 热点车辆数据配置
export function GetPointConfigCategories(data) {
  return request({
    url: '/api/app/hot-point/config-categories?area=' + data.area,
    method: 'get',
    params: data
  })
}

export function GetPointConfig(data) {
  return request({
    url: '/api/app/hot-point/config',
    method: 'get',
    params: data
  })
}

export function UpdatePointConfig(data) {
  return request({
    url: '/api/app/hot-point/config?area=' + data.area,
    method: 'put',
    data: data
  })
}

export function GetPointConfigDemands(data) {
  return request({
    url: '/api/app/hot-point/vehicle-demands',
    method: 'get',
    params: data
  })
}

// 站点信息
export function GetParkingById(data) {
  return request({
    url: `/api/app/parking/${data.id}`,
    method: 'get',
    params: data
  })
}

export function GetAllParkings(data) {
  return request({
    url: '/api/app/parking/parkings',
    method: 'get',
    params: data
  })
}

export function GetParkingLocations(data) {
  return request({
    url: '/api/app/parking/parking-locations',
    method: 'get',
    params: data
  })
}

// 车辆分析--丢单数据
export function GetLowPowerStatistics(data) {
  return request({
    url: '/api/app/lost-order/low-power-statistics',
    method: 'get',
    params: data
  })
}

export function GetLowPowerVehicles(data) {
  return request({
    url: '/api/app/lost-order/low-power-vehicles',
    method: 'get',
    params: data
  })
}

export function GetOperationStatistics(data) {
  return request({
    url: '/api/app/lost-order/operation-statistics',
    method: 'get',
    params: data
  })
}

export function GetOperationVehicles(data) {
  return request({
    url: '/api/app/lost-order/operation-vehicles',
    method: 'get',
    params: data
  })
}

export function GetVehicleLogEvents(data) {
  return request({
    url: '/api/app/vehicle-log/events',
    method: 'get',
    params: data
  })
}

export function GetVehicleLogs(data) {
  return request({
    url: '/api/app/vehicle-log',
    method: 'get',
    params: data
  })
}

export function GetUnlockStatistics(data) {
  return request({
    url: '/api/app/vehicle-log/unlock-statistics',
    method: 'get',
    params: data
  })
}

export function GetLostDayStatistics(data) {
  return request({
    url: '/api/app/lost-order/day-lost-statistics',
    method: 'get',
    params: data
  })
}

export function UpdateLostDayStatistics(data) {
  return request({
    url: '/api/app/lost-order/day-lost-statistics',
    method: 'put',
    data
  })
}
